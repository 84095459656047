import * as React from 'react';
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'

// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
import { Grid, IconButton, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Backdrop, CircularProgress  } from '@mui/material'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import { add_new_item_to_product, uploadImage } from 'modules/api/market-api';
import { screenSizes } from 'shared/styles/theme'

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function AddItemDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ item_detail_page_url, setItemDetailPageUrl ] = React.useState<string>('');
  const [ item_title, setItemTitle ] = React.useState<string>('');
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)
  const [ edited_descriptions, setEditedDescriptions ] = React.useState<any>([])
  const [files, setFiles] = React.useState<any>([])
  const [avatar, setAvatar] = React.useState<string | undefined>('')
  const [image_location_flag, setImageLocationFlag] = React.useState<string>('')

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    // setItemData(props.imgArr)
  }, [props.imgArr])

  const handleClose = () => {
    setItemDetailPageUrl('')
    setItemTitle('')
    setEditedDescriptions([])
    setFiles([])
    setAvatar('')
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        if (
          // files.length > 0 &&
          item_detail_page_url.trim() !== '' &&
          item_title.trim() !== '' &&
          edited_descriptions.length !== 0
        ) {

          setIsLoading(true)

          let image_url = ''

          if(files.length > 0) {
            let formData: FormData = new FormData()
            formData.append('file', files[0])
            let img_upload_result = await uploadImage(formData)
  
            if (img_upload_result.status === 'success') {
              image_url = img_upload_result.data.Location
            } else if (img_upload_result.status === 'error') {
              setIsLoading(false)
              props.setOpen(false)
              setItemDetailPageUrl('')
              setItemTitle('')
              setEditedDescriptions([])
              setFiles([])
              setAvatar('')
            }
          }

          let active_product_index = props.products.findIndex((ele:any) => ele.active === true)

          let added_result = await add_new_item_to_product(
            props.products[active_product_index]._id, 
            item_detail_page_url,
            item_title,
            edited_descriptions,
            image_url
          )

          if ( added_result.status === 'success' ) {

            props.setProducts((prev: any[]) => {
              let next = [...prev]
              let active_index = next.findIndex(ele => ele.active === true)

              next[active_index].items = [...added_result.add_result.items]

              return next
            })

            setIsLoading(false)
            props.setOpen(false);
            setItemDetailPageUrl("")
          } else if ( added_result.status === 'error' ) {
            setIsLoading(false)
            props.setOpen(false);
            setItemDetailPageUrl("")
          }
        }

        
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemDetailPageUrl(e.target.value)
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemTitle(e.target.value)
  }

  const handleChoiceArrChange = (e:any, choice_index:number) => {
    setEditedDescriptions((prev:any) => {
      let new_choices = [... prev]
      new_choices[choice_index] = e.target.value
      return new_choices
    })
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    
  }

  const removeChoice = (e:any, choice_index:number) => {
    setEditedDescriptions((prev:any) => {
      let new_choices = [... prev]
      new_choices.splice(choice_index, 1)
      return new_choices
    })
  }

  const addChoice = () => {
    setEditedDescriptions((prev:any) => {
      let new_choices = [... prev]
      // new_choices.push("")
      new_choices.push('')
      return new_choices
    })
  }

  const thumbs = files.map((file: any, index: number) => (
    <div className="imageuploadinner" key={index}>
      <img
        src={file.preview}
        onLoad={() => {
          URL.revokeObjectURL(file.preview)
        }}
      />
    </div>
  ))

  const { getRootProps: getRootFileProps, getInputProps: getInputFileProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      setImageLocationFlag('local')
      setFiles(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        })
      )
    },
    multiple: false,
  })

  return (
    <div>
      
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'lg'}>
        {
          isLoading === true ? 
          <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit"/>
                <p>&nbsp;&nbsp; Saving Data ...</p>
          </Backdrop> : <></>
        }
        
        <DialogTitle>Add New Item</DialogTitle>
        <DialogContent>
          <CustomGrid container spacing={0}>
            <CustomGrid item xs={3} >
              <CustomDiv1 className={'img_div'}>
                <Page>
                  <section className="imginner">
                    <aside className="imageupload">
                      {files.length > 0 ? (
                        thumbs
                      ) : avatar === '' || avatar === undefined ? (
                        <div className="imageuploadinner">{/* <img src={require('assets/cover.png').default} alt="Blank" /> */}</div>
                      ) : (
                        <div className="imageuploadinner">
                          <img src={avatar} alt="Avatar" />
                        </div>
                      )}
                      <div {...getRootFileProps({ className: 'dropzone' })}>
                        <input {...getInputFileProps()} />
                        <IconButton aria-label="more" id="long-button" aria-haspopup="true" className="icon_btn_right">
                          <InsertPhotoIcon />
                        </IconButton>
                      </div>
                    </aside>
                    <div className="dropzoneOuter"></div>
                  </section>
                </Page>
              </CustomDiv1>
            </CustomGrid>
            <CustomGrid item xs={9} >
              <CustomGrid container spacing={0}>
                <CustomGrid item xs={1} sx={{paddingTop: '8px'}}>
                  <DialogContentText>
                    Url:
                  </DialogContentText>
                </CustomGrid>
                <CustomGrid item xs={11}>
                  <TextField
                    autoFocus
                    margin="dense"
                    fullWidth
                    multiline
                    rows={3}
                    maxRows={3}
                    value={item_detail_page_url}
                    onChange={handleCategoryChange}
                    // onKeyDown={handleKeyDown}
                  />
                </CustomGrid>
              </CustomGrid>
              <CustomGrid container spacing={0}>
                <CustomGrid item xs={1} sx={{paddingTop: '8px'}}>
                  <DialogContentText>
                    Title:
                  </DialogContentText>
                </CustomGrid>
                <CustomGrid item xs={11}>
                  <TextField
                    autoFocus
                    margin="dense"
                    fullWidth
                    multiline
                    rows={1}
                    maxRows={1}
                    value={item_title}
                    onChange={handleTitleChange}
                    // onKeyDown={handleKeyDown}
                  />
                </CustomGrid>
              </CustomGrid>

              
            </CustomGrid>
            <CustomGrid item xs={12}>
              <DialogContentText>
                Description:
              </DialogContentText>
              {
                  edited_descriptions.length > 0 && edited_descriptions.map((choice:any, index:number) => {
                    return (
                      <div key={index}>
                        <CustomDiv1 className={"mui_grid_flex mui_grid_item_end mui_grid_padding_left"}>
                          <TextField
                            autoFocus
                            margin="dense"
                            fullWidth
                            value={choice.choice}
                            onChange={(e) => handleChoiceArrChange(e, index)}
                            onKeyDown={handleKeyDown}
                          />
                          <CustomCancelIcon fontSize="small" className={"delete_icon"} onClick={(e) => {removeChoice(e, index)}}/>
                        </CustomDiv1>
                      </div>
                    )
                  })
                }
                  <CustomDiv1 className={"mui_grid_flex mui_grid_item_end mui_grid_add"}>
                    <CustomAddIcon fontSize="small" className={"delete_icon"} onClick={(e) => {addChoice()}}/>
                  </CustomDiv1>
            </CustomGrid>
          </CustomGrid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const CustomGrid = styled(Grid)`
    
`

const CustomDiv1 = styled.div`
    &.mui_grid_flex {
        display: flex!important;
    }
    &.mui_grid_item_end {
        justify-content: end;
        align-items: center;
    }
    &.mui_grid_padding_left {
      // padding-left: 15px!important;
    }
    &.mui_grid_add {
      justify-content: center!important;
      margin: 15px 0 0!important;
    }  
`

const CustomCancelIcon = styled(RemoveCircleOutlineIcon)`
    cursor: pointer;
    &.delete_icon {
        margin-left: 10px;
    }
    &.delete_icon: hover {
        color: #ff3100cf!important;
        margin-left: 10px;
    }
`
const CustomAddIcon = styled(AddCircleOutlineIcon)`
    cursor: pointer;
    &.delete_icon: hover {
        color: #0070ff!important;
    }
`
const Page = styled.div`
  color: #fff;
  display: flex;
  justify-content: Center;
  align-items: Center;
  .imginner {
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    border-radius: 5px !important;
  }
  .imageupload {
    display: flex;
    justify-content: Center;
    border-radius: 10px;
    position: relative;
    .imageuploadinner {
      position: relative;
      @media (min-width: ${screenSizes.mediaM}px) {
        width: 200px;
        height: 200px;
      }
      width: 160px;
      height: 160px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
      .icon_btn {
        color: rgb(120, 115, 115);
        position: absolute;
        top: 30%;
        left: 30%;
        &:hover {
          background-color: white !important;
          color: black;
        }
      }
    }
    .icon_btn_right {
      color: rgb(120, 115, 115);
      position: absolute;
      top: 2%;
      right: 2%;
      &:hover {
        background-color: white !important;
        color: #049cf7;
      }
    }
  }
  .dropzoneOuter {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    max-width: 200px;
    margin: auto;
  }
`