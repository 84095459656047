import * as React from 'react';
import styled from 'styled-components'
import Switch from '@mui/material/Switch';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import EditIcon from '@mui/icons-material/Edit';
import DvrIcon from '@mui/icons-material/Dvr';
import Icon from '@mdi/react';
import { mdiBinoculars } from '@mdi/js';
import ControlPointIcon from '@mui/icons-material/ControlPoint';


export default function CustomProfileRow(props:any) {

    const {
        index, 
        profile, 
        click_edit_email_icon, 
        click_edit_linkedin_icon,
        click_add_email_icon, 
        change_show_option_status 
    } = props

    const [tag_checked, setTagChecked] = React.useState(true);

    React.useEffect(() => {
        // console.log(profile.show_option)
        if (profile.show_option === undefined || profile.show_option === true) {
            setTagChecked(true)
        } else if (profile.show_option === false) {
            setTagChecked(false)
        }
        
    }, [profile])


    const handleTagSwitchChange = (e: any, index: number) => {
        e.stopPropagation()
        setTagChecked(e.target.checked);
        // console.log(e.target.checked)
        change_show_option_status(index, e.target.checked)
    };

    const clickedTagSwitch = (e: any) => {
        e.stopPropagation()
    };

    return (
        <> 
            <div className='admin_pfs_flex_py_3' key={index}>
                <div className='admin_pfs_p_3_w_2_3'>
                    <Switch
                        checked={tag_checked}
                        onChange={(e:any) => {handleTagSwitchChange(e, index)}}
                        onClick={(e:any) => {clickedTagSwitch(e)}}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size="small"
                    />
                    <div className='admin_pfs_w_auto' style={profile.show_option === false ? {opacity: '30%'} : {}}>
                        <div className='admin_pfs_w_52px'>
                            {
                                profile.avatar_src !== "" ? 
                                <img src={profile.avatar_src} alt={profile.company_name} /> :
                                <CustomAccountCircleIcon className='custom_avatar_icon'/>
                            }
                        </div>
                    </div>

                    <div className='admin_pfs_grow_w_676' style={profile.show_option === false ? {opacity: '30%'} : {}}>
                        <div className='admin_pfs_w_full_flex'>
                            <div className='admin_pfs_font_semibold'>
                                <span className="admin_pfs_cursor_pointer">{profile.name}</span>
                                &nbsp;
                                {
                                    profile.linkedin_link === '' || profile.linkedin_link === undefined  ? <></>
                                    : 	<a href={profile.linkedin_link} target="_blank">
                                            <i className="fa-brands fa-linkedin" style={{color: "#0076b2"}} aria-hidden="true"></i>
                                        </a>
                                }
                                
                            </div>
                            <div className='admin_pfs_border_1'>

                            </div>
                            <div className='admin_pfs_text_xs'>
                            <CustomLocationOnIcon className='admin_pfs_text_icon'/>
                                <span className="admin_pfs_text_8B8B88">{profile.location}</span>
                            </div>
                        </div>

                        <div className='admin_pfs_flex_tems_start'>
                            <div className='admin_pfs_flex_justify_center_item'>
                                <CustomBusinessCenterIcon className='admin_pfs_brief_icon' />
                            </div>

                            <div className='admin_pfs_font_medium'>
                                {profile.role}
                            </div>
                        </div>

                        {/* <div className='admin_pfs_flex_items_center_gap_2'>
                            <div className='admin_pfs_flex_justify_center_item'>
                                <CustomCorporateFareIcon className='admin_pfs_buildings_icon'/>
                            </div>
                            <div className='admin_pfs_font_medium'>
                                {profile.company_name}
                            </div>
                        </div> */}

                        {
                            profile.company_info_obj.company === undefined ? <></>
                            : <div className='admin_pfs_flex_items_center_gap_2'>
                                    <div className='admin_pfs_flex_justify_center_item'>
                                        <CustomCorporateFareIcon className='admin_pfs_buildings_icon'/>
                                        {/* <i className="fa-regular fa-xs fa-buildings admin_pfs_buildings_icon" aria-hidden="true"></i> */}
                                    </div>
                                    <div className='admin_pfs_font_medium'>
                                        <div>Company</div>
                                        <div>&nbsp;</div>
                                        <div>-</div>
                                        <div>&nbsp;</div>
                                        <div>{profile.company_info_obj.company}</div>
                                    </div>
                                </div>
                        }

                        {
                            profile.company_info_obj.employees === undefined ? <></>
                            : <div className='admin_pfs_flex_items_center_gap_2'>
                                    <div className='admin_pfs_flex_justify_center_item'>
                                        <CustomGroupIcon className='admin_pfs_user_group_icon'/>
                                    </div>
                                    <div className='admin_pfs_font_medium'>
                                        <div>Employees</div>
                                        <div>&nbsp;</div>
                                        <div>-</div>
                                        <div>&nbsp;</div>
                                        <div>{profile.company_info_obj.employees}</div>
                                    </div>
                                </div>
                        }

                        {
                            profile.company_info_obj.revenue === undefined ? <></>
                            : <div className='admin_pfs_flex_items_center_gap_2'>
                                    <div className='admin_pfs_flex_justify_center_item'>
                                        <CustomGroupIcon className='admin_pfs_user_group_icon'/>
                                    </div>
                                    <div className='admin_pfs_font_medium'>
                                        <div>Revenue</div>
                                        <div>&nbsp;</div>
                                        <div>-</div>
                                        <div>&nbsp;</div>
                                        <div>{profile.company_info_obj.revenue}</div>
                                    </div>
                                </div>
                        }

                        {
                            profile.company_info_obj.category === undefined ? <></>
                            : <div className='admin_pfs_flex_items_center_gap_2'>
                                    <div className='admin_pfs_flex_justify_center_item'>
                                        <CustomLanguageIcon className='admin_pfs_category_icon'/>
                                    </div>
                                    <div className='admin_pfs_font_medium'>
                                        <div>Category</div>
                                        <div>&nbsp;</div>
                                        <div>-</div>
                                        <div>&nbsp;</div>
                                        <div>{profile.company_info_obj.category}</div>
                                    </div>
                                </div>
                        }

                        {
                            profile.company_info_obj.industry === undefined ? <></>
                            : <div className='admin_pfs_flex_items_center_gap_2'>
                                    <div className='admin_pfs_flex_justify_center_item'>
                                        <CustomLanguageIcon className='admin_pfs_category_icon'/>
                                    </div>
                                    <div className='admin_pfs_font_medium'>
                                        <div>Industry</div>
                                        <div>&nbsp;</div>
                                        <div>-</div>
                                        <div>&nbsp;</div>
                                        <div>{profile.company_info_obj.industry}</div>
                                    </div>
                                </div>
                        }

                        {
                            profile.company_info_obj.location === undefined ? <></>
                            : <div className='admin_pfs_flex_items_center_gap_2'>
                                    <div className='admin_pfs_flex_justify_center_item'>
                                        <CustomLocationOnIcon className='admin_pfs_text_icon'/>
                                    </div>
                                    <div className='admin_pfs_font_medium'>
                                        <div>Location</div>
                                        <div>&nbsp;</div>
                                        <div>-</div>
                                        <div>&nbsp;</div>
                                        <div>{profile.company_info_obj.location}</div>
                                    </div>
                                </div>
                        }

                        {
                            profile.company_info_obj.website === undefined ? <></>
                            : <div className='admin_pfs_flex_items_center_gap_2'>
                                    <div className='admin_pfs_flex_justify_center_item'>
                                        <CustomLinkIcon className='admin_pfs_website_icon'/>
                                    </div>
                                    <div className='admin_pfs_font_medium'>
                                        <div>Website</div>
                                        <div>&nbsp;</div>
                                        <div>-</div>
                                        <div>&nbsp;</div>
                                        <div className='admin_pfs_word_break'>{profile.company_info_obj.website}</div>
                                    </div>
                                </div>
                        }

                        {
                            profile.company_info_obj.description === undefined ? <></>
                            : <div className='admin_pfs_flex_items_center_gap_2'>
                                    <div className='admin_pfs_flex_justify_center_item'>
                                        <CustomFormatQuoteIcon className='admin_pfs_description_icon'/>
                                    </div>
                                    <div className='admin_pfs_font_medium_des'>
                                        <div className='admin_pfs_word_break_normal'>Description</div>
                                        <div className='admin_pfs_mobile_none'>&nbsp;</div>
                                        <div className='admin_pfs_mobile_none'>-</div>
                                        <div className='admin_pfs_mobile_none'>&nbsp;</div>
                                        <div className='admin_pfs_word_break'>{profile.company_info_obj.description}</div>
                                    </div>
                                </div>
                        }

                        {
                            // ele.company_info_obj === undefined ? <></>
                            profile.activity_info_obj === undefined ? <></>
                            : <div className='admin_pfs_flex_items_center_gap_2'>
                                    <div className='admin_pfs_flex_justify_center_item'>
                                        <CustomDvrIcon className='admin_pfs_description_icon'/>
                                        {/* <i className="fa-regular fa-xs fa-buildings admin_pfs_buildings_icon" aria-hidden="true"></i> */}
                                    </div>
                                    <div style={{width: '100%', fontSize: '12px'}}>
                                        <div className='admin_pfs_font_medium'>
                                            <div>Date and time</div>
                                            <div>&nbsp;</div>
                                            <div>-</div>
                                            <div>&nbsp;</div>
                                            <div>{profile.activity_info_obj.date_and_time}</div>
                                        </div>
                                        <div className='admin_pfs_font_medium'>
                                            <div>Page</div>
                                            <div>&nbsp;</div>
                                            <div>-</div>
                                            <div>&nbsp;</div>
                                            <div>{profile.activity_info_obj.page}</div>
                                        </div>
                                        <div className='admin_pfs_font_medium'>
                                            <div>Referer</div>
                                            <div>&nbsp;</div>
                                            <div>-</div>
                                            <div>&nbsp;</div>
                                            <div>{profile.activity_info_obj.referrer}</div>
                                        </div>
                                        <div className='admin_pfs_font_medium'>
                                            <div>Title</div>
                                            <div>&nbsp;</div>
                                            <div>-</div>
                                            <div>&nbsp;</div>
                                            <div>{profile.activity_info_obj.title}</div>
                                        </div>
                                    </div>
                                    
                                </div>
                        }

                    </div>
                </div>

                <div className='admin_pfs_p_3_w_1_3'  style={profile.show_option === false ? {opacity: '30%'} : {}}>
                    {
                        profile.emails.length > 0 && profile.emails.map((email_ele: any, email_index: number) => {
                            return (
                                <div className='admin_pfs_flex_items_start_gap' key={email_index}>
                                    <div className='admin_pfs_flex_justify_center_item'>
                                        <CustomMailOutlineIcon className='admin_pfs_envelope' />
                                    </div>

                                    <div className="admin_pfs_font_medium_text_xs">
                                        {email_ele}
                                        &nbsp;
                                        <CustomEditIcon className='admin_pfs_email_edit_icon' onClick={() => click_edit_email_icon(email_ele, index, email_index)}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                    

                    <div className='admin_pfs_flex_items_start_gap'>
                        <div className='admin_pfs_flex_justify_center_item'>
                            <CustomMailOutlineIcon className='admin_pfs_envelope' />
                        </div>

                        <div className="admin_pfs_font_medium_text_xs">
                            <CustomControlPointIcon className='admin_pfs_email_edit_icon' onClick={() => click_add_email_icon(index)}/>
                        </div>
                    </div>

                    <div className='admin_pfs_flex_items_start_gap'>
                   

                            <div className='admin_pfs_flex_items_start_gap_linkedin'>
                                <div className='admin_pfs_flex_justify_center_item'>
                                    <i className="fa-brands fa-linkedin" style={{color: "#0076b2"}} aria-hidden="true"></i>
                                </div>
                                {
                                profile.linkedin_link === '' || profile.linkedin_link === undefined  ? <></>
                                    :
                                    <a href={profile.linkedin_link} target="_blank" className='admin_linkedin_a'>
                                        <div className="admin_pfs_font_medium_text_xs">
                                            {profile.linkedin_link}
                                        </div>
                                    </a>
                                  } 
                            </div>
                               
                  
                    <div className="admin_pfs_font_medium_text_xs">
                        <CustomEditIcon 
                            className='admin_pfs_email_edit_icon' 
                            onClick={() => click_edit_linkedin_icon(profile.linkedin_link === '' || profile.linkedin_link === undefined  ? '' : profile.linkedin_link, index)}
                        />
                    </div>
                    </div>
                    
                    <div className='admin_pfs_flex_items_start_gap'>
                        <div className='admin_pfs_flex_justify_center_item'>
                            <CustomIcon path={mdiBinoculars} className='admin_pfs_binoculars'/>
                        </div>

                        <div className="admin_pfs_font_medium_text_xs">
                            {profile.last_seen}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const CustomEditIcon = styled(EditIcon)`
    &.admin_pfs_email_edit_icon {
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
        cursor: pointer;
    }

    &.admin_pfs_email_edit_icon:hover {
        color: #000;
    }
`
const CustomControlPointIcon = styled(ControlPointIcon)`
    &.admin_pfs_email_edit_icon {
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
        cursor: pointer;
    }

    &.admin_pfs_email_edit_icon:hover {
        color: #000;
    }
`
const CustomLocationOnIcon = styled(LocationOnIcon)`
    &.admin_pfs_text_icon {
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomBusinessCenterIcon = styled(BusinessCenterIcon)`
    &.admin_pfs_brief_icon {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomCorporateFareIcon = styled(CorporateFareIcon)`
    &.admin_pfs_buildings_icon {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomMailOutlineIcon = styled(MailOutlineIcon)`
    &.admin_pfs_envelope {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomAccountCircleIcon = styled(AccountCircleIcon)`
    &.custom_avatar_icon {
       width: 52px;
        height: 52px;
        color: #acaca9;
    }
`
const CustomGroupIcon = styled(GroupIcon)`
    &.admin_pfs_user_group_icon {
       font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomLanguageIcon = styled(LanguageIcon)`
    &.admin_pfs_category_icon {
       font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomLinkIcon = styled(LinkIcon)`
    &.admin_pfs_website_icon {
       font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`
const CustomFormatQuoteIcon = styled(FormatQuoteIcon)`
    &.admin_pfs_description_icon {
       font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`

const CustomDvrIcon = styled(DvrIcon)`
    &.admin_pfs_description_icon {
       font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
    }
`

const CustomIcon = styled(Icon)`
    &.pfs_binoculars {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: #acaca9;
        width: 16px;
        height: 16px;
    }
`
