import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button, Box   } from '@mui/material'
import { recordUserAndIds } from 'modules/api/market-api'
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import './product.css'

interface Props {
    item: any,
    num: number,
}

const ProductBox = (props: Props) => {
    const { item, num } = props;
    // console.log(item)
    const selector = useSelector((state: any) => state)

    const recordIds = (e: any, page_url: string) => {
        e.stopPropagation()
        let gclid = localStorage.getItem('gclid')
        let msclkid = localStorage.getItem('msclkid')
        let search = localStorage.getItem('search')
        let amazon_tracking_id = localStorage.getItem('amazon_tracking_id')
        let _id = localStorage.getItem('_id')
        recordUserAndIds(gclid, msclkid, amazon_tracking_id, item.page_url, search, _id, item.levanta_obj)
            .then(result => {
            })
            .catch(err => {
                // console.log(err)
            })
        window.open(`${page_url}`, '_blank')
    }

    return (

        num === 1 ? 
        <CustomDiv className='wrapper_div_num1' onClick={(e:any) => recordIds(e, item.page_url)}>
            <div className="badge_div">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="badge_svg">
                    <circle cx="12" cy="9" r="7" stroke-width="1.5">
                    </circle>
                    <path stroke-linecap="round" stroke-width="1.5" d="m7.351 15-.637 2.323c-.628 2.292-.942 3.438-.523 4.065.147.22.344.396.573.513.652.332 1.66-.193 3.675-1.243.67-.35 1.006-.524 1.362-.562a1.87 1.87 0 0 1 .398 0c.356.038.691.213 1.362.562 2.015 1.05 3.023 1.575 3.675 1.243.229-.117.426-.293.573-.513.42-.627.105-1.773-.523-4.065L16.649 15">
                    </path>
                </svg>
                <span className="badge_txt">Most Popular</span>
            </div>
            <CustomDiv className='wrapper_div_1'>
                <div className='card_div'>
                    <div className='card_sub_div_1'>
                        <div className='rank_num'>
                            #{num}
                        </div>

                        <div className='card_logo'>
                            {/* {item.title} */}
                            {
                                item.image_url === undefined || item.image_url?.trim() === '' ?
                                item.title :
                                <div className='imageuploadinner_card_logo'>
                                    <img
                                        src={item.image_url}
                                    />
                                </div>
                            }
                            
                        </div>

                        <div className='visit_btn' onClick={(e:any) => recordIds(e, item.page_url)}>
                            <span>Visit Site</span>
                        </div>
                    </div>

                    <div className='card_sub_div_2' >
                        <div className='rating_div'>
                            <span className='rating_txt_01'>
                                Our Rating
                            </span>
                            <span className='rating_txt_02'>
                                10.0
                            </span>
                            <span className='rating_txt_03'>
                                EXCELLENT
                            </span>
                        </div>

                        <div className='p_description_div'>
                            {
                                item.descriptions.length > 0 && item.descriptions.map((des: string, index: number) => {
                                    return (
                                        <div className='p_description_sub_div'>
                                            <CustomCheckIcon />
                                            <span className='p_description_text'>{des}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {/* <div className='visit_2_btn' onClick={(e:any) => recordIds(e, item.page_url)}>
                        <span>Visit Site</span>
                    </div> */}
                </div>
            </CustomDiv>
        </CustomDiv>
        
        :
            <CustomDiv className='wrapper_div'>
                <div className='card_div'>
                    <div className='card_sub_div_1'>
                        <div className='rank_num'>
                            #{num}
                        </div>

                        <div className='card_logo'>
                            {/* {item.title} */}
                            {
                                item.image_url === undefined || item.image_url?.trim() === '' ?
                                item.title :
                                <div className='imageuploadinner_card_logo'>
                                    <img
                                        src={item.image_url}
                                    />
                                </div>
                            }
                        </div>

                        <div className='visit_btn' onClick={(e:any) => recordIds(e, item.page_url)}>
                            <span>Visit Site</span>
                        </div>
                    </div>

                    <div className='card_sub_div_2' >
                        <div className='rating_div'>
                            <span className='rating_txt_01'>
                                Our Rating
                            </span>
                            <span className='rating_txt_02'>
                                9.8
                            </span>
                            <span className='rating_txt_03'>
                                EXCELLENT
                            </span>
                        </div>

                        <div className='p_description_div'>
                            {
                                item.descriptions.length > 0 && item.descriptions.map((des: string, index: number) => {
                                    return (
                                        <div className='p_description_sub_div'>
                                            <CustomCheckIcon />
                                            <span className='p_description_text'>{des}</span>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>

                    {/* <div className='visit_2_btn' onClick={(e:any) => recordIds(e, item.page_url)}>
                        <span>Visit Site</span>
                    </div> */}
                </div>
            </CustomDiv>
        
    )
}

const CustomDiv = styled.div`
    &.wrapper_div {
        // min-width: 800px;
        max-width: 800px;
        position: relative;
        margin: 0;
        box-sizing: border-box;
        cursor: pointer;
        margin-top: 10px;

        @media (max-width: 800px) {
            min-width: 100%;
        }
    }

    &.wrapper_div_1 {
        // min-width: 800px;
        max-width: 800px;
        position: relative;
        margin: 0;
        box-sizing: border-box;
        border: 3px solid #024b6d;
        cursor: pointer;

         @media (max-width: 800px) {
            min-width: 100%;
        }
    }

    &.wrapper_div_num1 {
        overflow: hidden;
        background-color: #024b6d;
        border-radius: 16px;
        position: relative;
        margin: 0;
        box-sizing: border-box;
        cursor: pointer;
    }
`

const CustomStarIcon = styled(StarIcon)`
    color: #f8b200;
`

const CustomStarHalfIcon = styled(StarHalfIcon)`
    color: #f8b200;
`

const CustomA = styled.a`
    text-decoration: none;
    width: 100%;
    &.wrapper_a {
        margin-bottom: 20px!important;
        border: 2px solid #EDEDED;
        border-radius: 22px;
        box-shadow: 0 0 10px rgba(0,0,0,0.08)!important;
        transition: all linear 0.2s;

        &:hover {
            border: 2px solid #ff8d00!important;
        }  
    }    
`

const CustomButton = styled(Button)`
`

const CustomCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)`
    color: #00cea6;
    font-size: 22px!important;
    font-weight: 600!important;
`
const CustomCheckIcon = styled(CheckIcon)`
    color: #00cea6;
    font-size: 22px!important;
    font-weight: 600!important;
`

const CustomBox = styled(Box)`
        width: 100%;
        height: 180px;
        display: flex;
        background: white;
        // margin: 10px 0;
        border-radius: 22px;
        position: relative;
        
        div.list_text_div {
            display: flex;
            align-items: center;
        }

        div.rating {
            text-decoration: none;
            color: #000;
            margin-left: 10px;
            display: flex;

            span.number {
                font-weight: 600;
            }
            
            span.total {
                color: gray;
            }
        }

        p.picked {
            color: #999999;
            padding-top: 10px;
            margin-top: 10px;
            border-top: 1px solid #e2e0da;
            font-size: 15px;
            margin-bottom: 0;
            // -webkit-letter-spacing: -0.1px;
            // -moz-letter-spacing: -0.1px;
            // -ms-letter-spacing: -0.1px;
            letter-spacing: -0.1px;
            line-height: 18px;
            width: 100%;
            text-align: center;
        }

        span.itemNumber {
            background-color: #fff;
            border-radius: 100%;
            display: block;
            height: 40px;
            line-height: 37px;
            width: 40px;
            border: 2px solid #000;
            position: absolute;
            top: 50%;
            margin-top: -20px;
            left: -20px;
            color: #000;
            // font-family: 'NT Somic Medium';
            // font-family: Inter;
            text-align: center;
            z-index: 1;
            font-size: 13px;
            box-sizing: border-box;
        }

        div.ribbon {
            background-color: #5b31dd;
            color: #ffffff;
            font-size: 12px;
            font-family: GT Pressura,sans-serif;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 0 10px;
            line-height: 30px;
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1;
        }

        div.ribbon:after {
            border-color: #5b31dd transparent #5b31dd #5b31dd;
            right: -20px;
            border-width: 15px;
            height: 0;
            width: 0;
            content: '';
            position: absolute;
            border-style: solid;
            top: 0px;
        }

        .left_box {
            background: white;
            margin: 0;
            width: 180px;
            display: flex!important;
            align-items: center!important;
            justify-content: center!important;
            padding-left: 30px;
            img { 
                // width: 90%!important;
                // height: 90%!important;
                object-fit: contain;
                
            }
        }
        .center_box {
            background: white;
            margin: 0;
            width: 100%;
            flex-grow: 1;
            display: flex!important;
            align-items: center!important;
            justify-content: start!important;
            padding-left: 30px;
            padding-right: 30px;
            img {
                width: 133px;
                height: 24px;
                margin-top: 5px;
              }
            .main_box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .part_box1{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-bottom: 10px;
                    
                    .part_box1_right {
                        font-family: GT Pressura,sans-serif;
                        font-weight: 700;
                        line-height: 1.33;
                        font-size: 24px;
                        letter-spacing: 0.01em;
                        color: #0f1a16;
                    }
                }
                .part_box2{
                    margin: 0px;
                    color: #000000;
                    // color: #008472;
                    // color: #1275bb;
                    display: -webkit-box;
                    // -webkit-line-clamp:  2;
                    -webkit-box-orient: vertical;
                    // display: flex;
                    overflow: hidden;
                    font-family: Inter, Arial, sans-serif;
                    // font-weight: 600;
                    font-size: 15px;
                    cursor: pointer;
                    &:hover {
                        // background-color: #edecec;
                    }
                }
            }
        }
        .right_box {
            background: white;
            margin: 0;
            width: 200px;
            display: flex!important;
            align-items: center!important;
            justify-content: end!important;
            padding-right: 45px;
            img {
                width: 133px;
                height: 24px;
                margin-top: 5px;
              }
            .MuiBox-root {
                width: 170px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center!important;
            }
            &.right_box_wrapper {
                // width: 170px;
                // display: flex;
                // flex-direction: column;
                // align-items: center;
                // justify-content: center!important;
            }
        }
        ${CustomButton} {
            // width: 170px;
            width: 100%;
            height: 50px;
            padding: 0 10px;
            cursor: pointer;
            font-family: sans-serif;
            font-weight: 600;
            line-height: 1.33;
            font-size: 14px!important;
            letter-spacing: 0.03em!important;
            color: white!important;
            background-color: #f6ae18!important;
            border: 1px solid #f6ae18!important;
            text-transform: uppercase;
            
            &:hover {
                border: 1px solid #f2a842!important;
                background-color: #f09637!important;
            }  
        }
`

export default ProductBox