import { useRef, useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode'
import history from 'modules/app/components/history'
import styled from 'styled-components'
import './admin_profiles.css'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { read_rb2b_profiles_info_from_aws_s3, update_item_show_option_status } from 'modules/api/market-api'
import EditItemEmailDialog from './sub_components/EditItemEmailDialog'
import EditItemLinkedinUrlDialog from './sub_components/EditItemLinkedinUrlDialog'
import AddEmailDialog from './sub_components/AddEmailDialog'
import CustomProfileRow from './sub_components/CustomProfileRow'
import { Grid, Box } from '@mui/material'
import Test from 'modules/test'
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

const Admin_profiles = (props: any) => {
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ isFirstLoading, setIsFisrtLoading ] = useState<boolean>(false)
	const [profiles_obj_arr, setProfilesObjArr] = useState<any>([])
    const [editItemEmailDialogOpen, setEditItemEmailDialogOpen] = useState<boolean>(false)
    const [editItemLinkedinUrlDialogOpen, setEditItemLinkedinUrlDialogOpen] = useState<boolean>(false)
    const [addEmailDialogOpen, setAddEmailDialogOpen] = useState<boolean>(false)
    const [edited_item_index, setEditedItemIndex] = useState<number>(-1)
    const [add_item_index, setAddItemIndex] = useState<number>(-1)
    const [edited_item_email_index, setEditedItemEmailIndex] = useState<number>(-1)
    const [edited_item_email, setEditedItemEmail] = useState<string>('')
    const [edited_item_linkedin_url, setEditedItemLinkedinUrl] = useState<string>('')

    useEffect(() => {
        (async () => {
          if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
              const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))
              if ( decoded.id == localStorage.getItem('userDbId') ) {
                setIsFisrtLoading(true)

				const read_profiles_obj_arr_result = await read_rb2b_profiles_info_from_aws_s3()
				if (read_profiles_obj_arr_result.status === 'success') {
					const temp_profiles_obj_arr = read_profiles_obj_arr_result.result.profiles_obj_arr
					// console.log(temp_profiles_obj_arr)
					setProfilesObjArr(temp_profiles_obj_arr)
                    setIsFisrtLoading(false)                    

				} else if (read_profiles_obj_arr_result.status === 'fail') {
                    setIsFisrtLoading(false)                    
					history.push(`/admin/signin`)
				}
              } else {
                  history.push(`/admin/signin`)
              }
          } else {
              history.push(`/admin/signin`)
          }
        })()
          
      }, [])

      const click_edit_email_icon = (email: string, index: number, email_index: number) => {
        (async () => {
            // console.log(email)
            // console.log(index)
            // console.log(email_index)
            setEditedItemEmail(email.trim())
            setEditedItemIndex(index)
            setEditedItemEmailIndex(email_index)
            setEditItemEmailDialogOpen(true)
        })()
      }

      const click_edit_linkedin_icon = (linkedin_url: string, index: number) => {
        (async () => {
            // console.log(linkedin_url)
            // console.log(index)
            setEditedItemLinkedinUrl(linkedin_url.trim())
            setEditedItemIndex(index)
            setEditItemLinkedinUrlDialogOpen(true)
        })()
      }

      const click_add_email_icon = (index: number) => {
        (async () => {
            // console.log(index)
            setAddItemIndex(index)
            setAddEmailDialogOpen(true)
        })()
      }

      const change_show_option_status = (index:number, status: boolean) => {
        (async () => {
            
            setIsLoading(true)
            
            let update_result:any = await update_item_show_option_status(index, status)
// console.log(update_result)
            if ( update_result.result.status === "success" ) {
                setIsLoading(false)
                await new Promise(r => setTimeout(r, 300))
                // setContentItems(update_result.update_result.items)
                
                setProfilesObjArr((prev:any[]) => {
                    let next = [...prev]

                    next[index] = Object.assign(next[index], {show_option: status})

                    return next
                })
            } else if ( update_result.status === "error" ) {
                setIsLoading(false)
            }

        })()
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Test />
                <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
                >
                    <Toolbar sx={{minHeight: '48px!important'}}/>
                    <Container sx={{ mt: 0, mb: 0, pl: '0!important', pr: '0!important', maxWidth: '100%!important' }}>
                        {
                            isLoading === true || isFirstLoading === true ?
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={isLoading || isFirstLoading}
                                // onClick={handleClose}
                            >
                                <CircularProgress color="inherit"/>
                                <p>&nbsp;&nbsp; {isLoading === true? `Saving Data ...` : isFirstLoading === true ? `Loading Data ...` : ``}</p>
                            </Backdrop> :<></>
                        }
                        <div className='admin_pfs_main_container'>
                            <div className='admin_pfs_main_wrapper'>
                                <div className='admin_pfs_rounded'>

                                    {
                                        profiles_obj_arr?.length === 0 ? <></> : profiles_obj_arr?.map((profile:any, index:number) => {
                                            return (
                                                <CustomProfileRow 
                                                    key={index}
                                                    index={index}
                                                    profile={profile}
                                                    click_edit_email_icon={click_edit_email_icon}
                                                    click_edit_linkedin_icon={click_edit_linkedin_icon}
                                                    click_add_email_icon={click_add_email_icon}
                                                    change_show_option_status={change_show_option_status}
                                                />
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>

                        <EditItemEmailDialog 
                            open={editItemEmailDialogOpen} 
                            setOpen={setEditItemEmailDialogOpen} 
                            edited_item_index={edited_item_index}
                            setEditedItemIndex={setEditedItemIndex}
                            edited_item_email_index={edited_item_email_index}
                            setEditedItemEmailIndex={setEditedItemEmailIndex}
                            edited_item_email={edited_item_email}
                            setEditedItemEmail={setEditedItemEmail}
                            profiles_obj_arr={profiles_obj_arr} 
                            setProfilesObjArr={setProfilesObjArr} 
                        />

                        <EditItemLinkedinUrlDialog 
                            open={editItemLinkedinUrlDialogOpen} 
                            setOpen={setEditItemLinkedinUrlDialogOpen} 
                            edited_item_index={edited_item_index}
                            setEditedItemIndex={setEditedItemIndex}
                            edited_item_linkedin_url={edited_item_linkedin_url}
                            setEditedItemLinkedinUrl={setEditedItemLinkedinUrl}
                            profiles_obj_arr={profiles_obj_arr} 
                            setProfilesObjArr={setProfilesObjArr} 
                        />

                        <AddEmailDialog 
                            open={addEmailDialogOpen} 
                            setOpen={setAddEmailDialogOpen} 
                            add_item_index={add_item_index}
                            setAddItemIndex={setAddItemIndex}
                            profiles_obj_arr={profiles_obj_arr} 
                            setProfilesObjArr={setProfilesObjArr} 
                        />
                    </Container>
                </Box>
            </Box>
            

        </>                                                             
    );
};



export default Admin_profiles