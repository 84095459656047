import { useState } from 'react'
import styled from 'styled-components'
import { Grid, Divider } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProductBoxMobile from './components/ProductBoxMobile';
import history from 'modules/app/components/history'
import TrustBoxMobile from './components/TrustBoxMobile'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import OverviewBoxMobile from './components/OverviewBoxMobile'
import BuyersGuideBoxMobile from './components/BuyersGuideBoxMobile'

const MobileDiv = (props: any) => {
    const { items, current_date, title, product, overview, buyer_guide, buyer_guide_title, buyer_guide_image_url, isHoverDisabled, setIsTooltipVisible, handleClick, isTooltipVisible, tooltipRef, click_got_it } = props

    const goStartPage = () => {
        history.push(`/`)
    }

    return (
        <CustomWrapperItems>
              <CustomGrid container spacing={0} style={{backgroundColor: '#024b6d', paddingTop: '10px', paddingBottom: '10px'}}>
                <CustomGrid item container spacing={0} sm={12} className="grid-header-left grid-flex-item-start">
                    <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                        <CustomGrid item  sm={12} className="margin_padding_0_v_h_0">
                            <CategoryTitle2>Best {product} of 2024</CategoryTitle2>
                        </CustomGrid>
                    </CustomGrid>
                    <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                        <CustomGrid item sm={12} className="margin_padding_0_v_h_0">
                            {/* <UpdatedSpan2>Last Updated: December 11, 2024</UpdatedSpan2> */}
                            <UpdatedSpan2>Last Updated: {current_date}</UpdatedSpan2>
                        </CustomGrid>
                    </CustomGrid>
                </CustomGrid>
            </CustomGrid> 
            {/* <Divider sx={{ boxShadow: '0px 1px 2px #0a0909'}} /> */}
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '5px!important', padding: '0!important'}}>
                <CustomGrid item container spacing={0} sm={12} className="grid-header-left grid-header-right grid-flex-item-start" sx={{background: 'transparent!important'}} >
                    {   items.length !== 0 ?
                        items.length > 0 && items.map((item: any, index: number)=> {
                            return <ProductBoxMobile key={index} item={item} num={index + 1}/>
                        }) : ''
                    }
                     <CustomDiv className='wrapper_div'>
                          <p className="under_descrption">Our partners provide compounded medications that have not undergone independent review or approval by the FDA for safety or effectiveness.</p>
                          <hr className="divider"></hr>
                      </CustomDiv> 
                </CustomGrid>
              </CustomGrid>
            </Box>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '20px!important', justifyContent: 'center', maxWidth: '1320px'}}>
              <CustomGrid item container spacing={0} className="" sx={{maxWidth: '800px', padding: '0'}}>
                <CustomGrid item  sm={12} className="margin-padding-0_v-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <CustomDiv className='wrapper_div'>
                        {/* <h2 className='description_main_title'>Weight Loss Medication FAQs</h2> */}
                        <h2 className='description_main_title'>{product} FAQs</h2>

                        <h3 className='description_sub_title'>How quickly will I see results?</h3>
                        <p className='description_txt'>Some medications, like Semaglutide, may show results as soon as four weeks, but the specific timeline will be dependent upon the medication and your specific weight loss plan.&nbsp;</p>

                        <h3 className='description_sub_title'>How long do I need to take weight loss medication?</h3>
                        <p className='description_txt'>You should consult with your doctor to find the right timeframe for your needs. In some cases, you may only need to take weight loss medications for a 12-week period or less. In other cases, this may be longer and may require more regular check-ins to ensure progress is proceeding as expected.&nbsp;</p>

                        <h3 className='description_sub_title'>Are these medications safe for long-term use?</h3>
                        <p className='description_txt'>Yes, there are certain weight loss medications that have been approved by the FDA for long-term use, including Semaglutide, Tirzepatide, and Liraglutide.&nbsp;</p>

                        <h3 className='description_sub_title'>What happens if I miss a dose?</h3>
                        <p className='description_txt'>Missing one dose should not have a noticeable effect on your progress, but multiple missed doses or not following the medication plan can extend your plan or disrupt it. Once you set a plan with your doctor, you should follow it as closely as possible. If you're experiencing issues, speak to your provider to find a better plan that works for you. You should avoid taking double doses to make up for lost time. Always follow the medical advice given to you by your provider based on the medication you are using.&nbsp;</p>

                        <h3 className='description_sub_title'>Can I take weight loss medications if I'm trying to get pregnant?</h3>
                        <p className='description_txt'>It is not advised to take weight loss medications while trying or actively pregnant. Medication brands like Wegovy®, Zepbound®, Mounjaro®, and Saxenda® state that taking the medications may be harmful to your unborn baby.&nbsp;</p>

                        <h3 className='description_sub_title'>Can I drink alcohol while on weight loss medications?</h3>
                        <p className='description_txt'>Generally it is wise to limit your consumption of alcohol or avoid it altogether while taking medications due to the potential negative side effects. While certain medications, like Ozempic®, may not have specific known interactions with alcohol, it can still worsen or increase the impact of side effects. Mixing medications with alcohol can damage your liver and worsen stomach-related symptoms. However, if this is a concern for you, speak with your doctor to find the right medication for your needs.&nbsp;</p>

                        <hr className="divider"></hr>

                    </CustomDiv>  
                </CustomGrid>
            </CustomGrid>
            <CustomGrid item container spacing={0} className="" sx={{maxWidth: '800px', padding: '0'}}>
                <CustomGrid item sm={12} className=""  sx={{maxWidth: '800px', padding: '0'}}>
                    <CustomDiv className='wrapper_div'>
                        <h3 className='description_sub_title' style={{color: '#000', marginTop: '0'}}>Our Top Recommendation</h3>
                        {items.length > 0 && <ProductBoxMobile item={items[0]} num={1}/>}
                        <p></p>
                    </CustomDiv>  
                </CustomGrid>
            </CustomGrid>
              </CustomGrid>
            </Box>
            

        </CustomWrapperItems> 
    );
};

const CustomBox = styled(Box)`
  &.tab_box {
    span.css-1aquho2-MuiTabs-indicator {
      background-color: #1e947e!important;
    }
    span.css-ttwr4n {
      background-color: #1e947e!important;
    }
  }

  &.parent_box {
    width: 100%;
    display: flex;
    background: aliceblue;
    margin: 10px 0;
    border-radius: 10px;
    position: relative;
    .center_box {
        background: white;
        margin: 0;
        width: auto;
        flex-grow: 1;
        display: flex!important;
        align-items: center!important;
        justify-content: start!important;
        // padding: 20px 15px;
        img {
            width: 133px;
            height: 24px;
            margin-top: 5px;
          }
        .main_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .overview_text {
                color: #636060;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5;
                letter-spacing: 0.00938em;
            }
        }
    }
  }
`

const CustomChevronRightIcon = styled(ChevronRightIcon)`
    margin: 0 5px;
`

const ImageAndTextM = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: 5px;
  img {
    // width: 25px!important;
    // height: 25px!important;
    margin-right: 5px;
  }
`

const CardSubTextM = styled.p`
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    color: #187664;
    // color: #06bbf1;
    // font-family: 'Montserrat',sans-serif;
    font-family: GT Pressura, sans-serif;
    margin: 0;
`

const CustomWrapperItems = styled.div`
padding-top: 64px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none;
    }
`

const CustomGrid = styled(Grid)`
&.advertising_grid {
    display: flex;
    justify-content: flex-end;
    // align-items: center;
    align-items: flex-end;

    div.advertising_grid_wrapper {
        position: relative;
    }

    div.advertising_grid_text {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        color: rgb(113, 110, 118);
    }
    
    div.advertising_text_tooltip {
        right: -40%;
        top: 45px;
        position: absolute;
        z-index: 2;
        background-color: #f1efe9;
        width: 160%;
        padding: 5px 20px 15px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3rem;
        text-align: center;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: default;

        @media (max-width: ${screenSizes.mediaM}px) {
            right: 0%;
        }
    }

    div.advertising_got_it_btn {
        padding: 5px 10px;
        background-color: #f38009;
        border-radius: 5px;
        color: white;
        width: 80%;
        cursor: pointer;
    }

    div.advertising_got_it_btn:hover {
        background-color: #f79e41;
       
    }
  }

  &.category_mobile_pd {
        @media (max-width: ${screenSizes.mediaM}px) {
            // display: none;
            padding: 8px 0px!important;
        }
    }

  &.category_mobile_pd_01 {
        @media (max-width: ${screenSizes.mediaM}px) {
            // display: none;
            padding: 0px 0px!important;
        }
    }

  // background-color: white!important;

  padding: 2px 10px;
  margin: 0px!important;

  img {
    // width: 192px;
    // height: 48px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  &.margin_padding_0_v_h_0 {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    margin: 2px 0!important;
    padding: 0!important;
  }

  .grid-header-left {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-left: 105px!important;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-left: 155px!important;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-left: 185px!important;
    }
  }

  .grid-header-right {
    align-items: center;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }
  .margin-padding-0_v-center {
    display: flex;
    align-items: center;
    margin: 2px 0!important;
    justify-content: center;
    padding: 0!important;
  }
 
  .imageTxt {
      margin: 0 10px 0 5px;
      color: gray!important;
  }

  span.buyer_guide_title_sub {
    letter-spacing: 0.01em;
    color: #747574;
    font-family: Inter,Arial,sans-serif;
    line-height: 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 16px;
  }

  .img_wrapper_grid {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
  }
  .img_div_wrapper {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;

      span.img {
        display: flex;
        justify-content: center;
        border-radius: 20px;
        overflow: hidden;
        margin-bottom: 20px;
        text-align: center;

        img {
          object-fit: contain;
          width: 100%;
        }
      }
  }
`

const CustomDiv = styled.div`
    &.wrapper_div {
        max-width: 800px;
        position: relative;
        margin: 0;
        box-sizing: border-box;
        cursor: pointer;

        @media (max-width: 800px) {
            min-width: unset;
        }

        .under_descrption {
            font-size: 12px;
            font-style: italic;
            margin-top: 20px;
            color: #325c68;
        }

        .divider {
            height: 0;
            margin: 20px 0;
            border: none;
            border-top: 3px solid #003544;
        }

        .description_main_title {
            font-size: 32px;
            font-weight: 800;
            margin-top: 0;
            margin-bottom: .86em;
            line-height: 2.5rem;
            overflow-wrap: break-word;
            font-family: GT Pressura,sans-serif;
            color: #003544;
        }

        .description_sub_title {
            font-size: 24px;
            line-height: 2rem;
            font-weight: 600;
            margin-top: 1.45em;
            margin-bottom: .85em;
            overflow-wrap: break-word;
            font-family: GT Pressura,sans-serif;
            color: #003544;
        }

        .description_txt {
            font-size: 16px;
            line-height: 26px;
            margin: 0;
            margin-top: 1.1428em;
            margin-bottom: 1.1428em;
            overflow-wrap: break-word;
            font-family: GT Pressura,sans-serif;
            color: #003544;

        }
    }
`

const ShortSpan = styled.span`
    cursor: pointer!important;
    font-family: sans-serif;
    color: gray;
    &:hover {
        color: black;
    }
    &.lastSpan {
      font-size: 15px!important;
        // color: #187664!important;
        // color: #37a4f1!important;
    }
`
const CategoryTitle = styled.span`
    font-family: GT Pressura,sans-serif;
    color: black!important;
    font-weight: 600;
    font-size: 26px;
`
const UpdatedSpan = styled.span`
    color: #5e5c62;
    font-size: 24px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 1rem;
    box-sizing: border-box;

    &.buyer_guide_span {
    font-size: 16px;
    }
`

const CategoryTitle2 = styled.span`
    font-family: GT Pressura,sans-serif;
    color: #fff!important;
    font-weight: 600;
    font-size: 32px;
    text-align: center;
`
const UpdatedSpan2 = styled.span`
    font-family: Inter,Arial,sans-serif;
    color: #fff!important;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
`
const CustomTab = styled(Tab)`
    &.lowcasetab {
      text-transform: none!important;
      width: 50%!important;
        
    } 
    &.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
      color: #1e947e!important;
    }
    &.css-1q2h7u5.Mui-selected {
      color: #1e947e!important;
    }
`
export default MobileDiv